import React from "react"
import Layout from "../components/layout/Layout/Layout"
import { graphql } from "gatsby"
import Details from "../components/Details/Details"
import HeroBanner from "../components/HeroBanner/HeroBanner"
import { FaRegCalendarAlt } from "react-icons/fa"

// Todo Add listing data for content types other than Pages
export const query = graphql`
  query($slug: String!) {
    datoCmsEvent(slug: { eq: $slug }) {
      id
      title
      description
      locationInfo
      heroImages {
        alt
        url
        title
      }
      calendarDate: eventDate(formatString: "DD/MM/Y")
      date: eventDate(formatString: "MMMM DD, dddd")
      month: eventDate(formatString: "MMMM")
      year: eventDate(formatString: "Y")
      startTime
      endTime
      links {
        linkUrl
        linkText
      }
      descriptionUsedForListings
      heroImages {
        alt
        fluid(maxWidth: 768, imgixParams: { fm: "jpg", auto: "compress" }) {
          ...GatsbyDatoCmsFluid
        }
        title
      }
      seo {
        image {
          url
        }
        description
        title
        twitterCard
      }
    }
  }
`

const buildBreadcrumbUrl = (slug, month, year) => {
  slug = slug.split("/")
  slug = "/" + slug[slug.length - 1]
  const currentYear = new Date().getFullYear()
  if (currentYear === parseInt(year)) {
    return "whats-on/" + month.toLowerCase() + slug
  } else {
    return "whats-on/" + month.toLowerCase() + "-" + year + slug
  }
}

const Business = props => {
  return (
    <Layout
      metaTitle={
        props.data.datoCmsEvent.seo
          ? props.data.datoCmsEvent.seo.title
          : props.data.datoCmsEvent.title
      }
      metaDescription={
        props.data.datoCmsEvent.seo
          ? props.data.datoCmsEvent.seo.description
          : null
      }
      metaImage={
        props.data.datoCmsEvent.seo && props.data.datoCmsEvent.seo.image
          ? props.data.datoCmsEvent.seo.image.url
          : null
      }
      twitterCard={
        props.data.datoCmsEvent.seo && props.data.datoCmsEvent.seo.twitterCard
          ? props.data.datoCmsEvent.seo.twitterCard
          : null
      }
      pathname={props.uri}
    >
      <HeroBanner />
      <Details
        leftOrRight="left"
        isBeach={false}
        title={props.data.datoCmsEvent.title}
        imageSrc={props.data.datoCmsEvent.heroImages[0].fluid}
        imageAlt={props.data.datoCmsEvent.heroImages[0].alt}
        imageTitle={props.data.datoCmsEvent.heroImages[0].title}
        date={props.data.datoCmsEvent.date}
        time={
          props.data.datoCmsEvent.startTime && props.data.datoCmsEvent.endTime
            ? `${props.data.datoCmsEvent.startTime} - ${props.data.datoCmsEvent.endTime}`
            : "Timings to be Confirmed"
        }
        address={props.data.datoCmsEvent.locationInfo}
        calendarDate={props.data.datoCmsEvent.calendarDate}
        calendarStartTime={props.data.datoCmsEvent.startTime}
        calendarEndTime={props.data.datoCmsEvent.endTime}
        description={props.data.datoCmsEvent.description}
        links={props.data.datoCmsEvent.links}
        addToCalIcon={<FaRegCalendarAlt />}
        addToCalText="Add to calendar"
        breadcrumbUrl={buildBreadcrumbUrl(
          props.uri,
          props.data.datoCmsEvent.month,
          props.data.datoCmsEvent.year
        )}
      />
    </Layout>
  )
}

export default Business
